import {
  DataFailed,
  Empty,
  LockContent,
  media,
  Text,
} from "@kablamooss/geo-ds-core-components";
import styled, { css } from "styled-components";

type PlaceholderCardSize = "sm" | "md";

interface StyledPlaceholderCardProps {
  center?: boolean;
  flex?: boolean;
  size?: PlaceholderCardSize;
}

const StyledPlaceholderCard = styled.div<StyledPlaceholderCardProps>`
  ${(p) =>
    p.flex &&
    css`
      flex: 1;
    `}
  ${(p) =>
    p.center &&
    css`
      align-self: center;
    `}
  display: grid;
  padding: 3rem 1rem;
  place-content: center;
  text-align: center;
  gap: 1rem;
  background: ${(p) => p.theme.colors.neutrals.surface};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  box-shadow: ${(p) => p.theme.boxShadows.cardWeak};

  @media ${media.lg} {
    padding: 3rem 2.5rem;
  }
`;

const StyledIllustration = styled.div`
  margin: 0 auto;
`;

const StyledContent = styled.div`
  display: grid;
  text-align: center;
`;

type PlaceholderCardStatus = "empty" | "error" | "lock";

const illustrations: Record<PlaceholderCardStatus, ReactSVGComponent> = {
  empty: Empty,
  error: DataFailed,
  lock: LockContent,
};

export interface PlaceholderCardProps {
  center?: boolean;
  cta?: React.ReactNode;
  flex?: boolean;
  "data-testid"?: string;
  size?: PlaceholderCardSize;
  status: PlaceholderCardStatus;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
}

const PlaceholderCard = ({
  center,
  cta,
  "data-testid": dataTestId,
  flex,
  subtitle,
  title,
  size,
  status,
}: PlaceholderCardProps) => {
  const Illustration = illustrations[status];

  return (
    <StyledPlaceholderCard center={center} data-testid={dataTestId} flex={flex}>
      <StyledIllustration>
        <Illustration
          data-testid={dataTestId && `${dataTestId}-${status}`}
          height={128}
        />
      </StyledIllustration>
      <StyledContent>
        <Text size={size === "sm" ? "subtitleMd" : "subtitleLg"}>{title}</Text>
        {subtitle && (
          <Text size={size === "sm" ? "bodyDefault" : "bodyLg"} variant="weak">
            {subtitle}
          </Text>
        )}
      </StyledContent>
      {cta && (
        <Text size={size === "sm" ? "bodyDefault" : "bodyLg"} variant="weak">
          {cta}
        </Text>
      )}
    </StyledPlaceholderCard>
  );
};

export default PlaceholderCard;
